<template>
  <div>
    <header class="s-header-collection">
        <div class="s-header-filter" @click="filterNav()">
            <h3 class="f-st">Collection</h3>
            <h2 v-if="!slug && !sub" class="f-hl">
                All Collections
            </h2>
            <h2 v-if="slug && !sub" class="f-hl">
                {{trimName(slug)}}
            </h2>
            <h2 v-if="slug && sub" class="f-hl">
                {{trimName(sub)}}
            </h2>
        </div>
        <div class="s-header-background" data-background="#ffffff"></div>
    </header>
    <section class="s-collection">
        <loaderProduct  v-if="loader"/>
        <div v-if="products.length != 0" class="s-collection-overview" >
            <div v-for="(items,index) in products" :key="index" v-if="items.status == 'active' || items.status == 'Active'" class="s-collection-product">
                <router-link v-for="(img,ind) in items.images"  :key="ind" class="item1"  :to="{path:'/productdetail/' + items.collections[0].slug + '/' +items.id}">
                    <img class="s-collection-thumbnail" v-if="img.default == 'Yes' && items && items.images && items.images.length" :src=" $store.state.file_url + img.image[0].src + '?alt=media'">
                </router-link>
                <router-link class="image-hover" :to="{path:'/productdetail/' + items.collections[0].slug + '/' +items.id}">                
                    <img class="s-collection-thumbnail" v-if="items && items.images && items.images.length" :src=" $store.state.file_url + items.images[0].image[0].src + '?alt=media'">
                </router-link>
                <router-link class="s-collection-info" :to="{path:'/productdetail/' + items.collections[0].slug + '/' +items.id}">
                    <h4 class="f-st" v-if="items && items.collections && items.collections[0].slug">{{trimName(items.collections[0].slug)}}</h4>
                    <h3 class="f-hp">{{items.name}}</h3>
                    <p class="f-hp" v-if="items && items.variants_classification && items.variants_classification[0].price && items.variants_classification[0].price != 0 && currency == 'PKR'">{{currency}} {{items.variants_classification[0].price}}/-</p>
                    <p class="f-hp" v-if="items && items.variants_classification && items.variants_classification[0].price && items.variants_classification[0].price != 0 && currency == 'USD'">{{currency}} {{convertCurrency(items.variants_classification[0].price)}}/-</p>
                </router-link>
            </div>            
        </div>
        <div v-else class="section3">
            <img src="@/assets/logo.png" style="width:10%" alt="">
            <p>There are no products in this section</p>
            <a  href="/#/" class="f-bs" data-id="1731">
                <svg class="f-bs-arrow" viewBox="0 0 6 10">
                <path
                    d="M0,9.9C1.4,8.3,2.9,5.6,3.4,5C2.9,4.4,1.4,1.7,0,0.1L0.1,0C1.5,1.6,5.8,4.8,6,5 c-0.2,0.2-4.5,3.4-5.9,5L0,9.9z"
                ></path>
                </svg>
                <svg class="f-bs-arrow" viewBox="0 0 6 10">
                <path
                    d="M0,9.9C1.4,8.3,2.9,5.6,3.4,5C2.9,4.4,1.4,1.7,0,0.1L0.1,0C1.5,1.6,5.8,4.8,6,5 c-0.2,0.2-4.5,3.4-5.9,5L0,9.9z"
                ></path>
                </svg>
                <span class="f-bs-line"></span> <i class="f-bs-label">Go To Home</i>
            </a>
        </div>
        
    </section>
  </div>
</template>

<script>
export default {
    components:{
        loaderProduct: () => import('../components/loaderProduct.vue'),
    },
    data(){
        return{
            count: 360,
            scrollY: '',
            active: false,
            col: '',
            name: '',
            icon: '',
            sub: '',
            error: false,
            products: {},
            loaders: {
                a:'',
                b: '',
                c: ''
            },
            loader: false,
            currency: ''
        }
    },
    watch: {
        "$route.params": {
        handler(newValue, preValue) {
            this.slug = ''
            this.sub = ''
            this.init()
        },
        immediate: true
        }
    },
    created(){
        this.loader = true
        // this.init()
    },
    methods:{
        init(){
            if(localStorage.getItem('currency')){
                this.currency = JSON.parse(localStorage.getItem('currency')) 
            }
            if(this.$route.params.slug){
                this.slug = this.$route.params.slug;
            }
            if(this.$route.params.subCol){
                this.sub = this.$route.params.subCol;
            }
            if(!this.slug && !this.sub){
                this.$store.dispatch("ProductRead", {
                            bool: {
                                must:[
                                    {
                                        match_phrase: {
                                            status: 'active'
                                        }
                                    }
                                ],
                                should: [
                                    {
                                        match_phrase: {
                                            event: this.$store.state.event
                                        }
                                    },
                                ],
                                minimum_should_match: 1
                            }
                        
                })
                .then(response => {
                    this.products = {}
                    setTimeout(() => {
                        this.products = response
                        this.loader = false
                    },100)
                })
            }
            if(this.slug && !this.sub){
                this.$store.dispatch("ProductRead", {
                            bool: {
                                must:[
                                    {
                                        match_phrase: {
                                            status: 'Active'
                                        }
                                    }
                                ],
                                should: [
                                    {
                                        match_phrase: {
                                            event: this.$store.state.event
                                        }
                                    },
                                    {
                                        match_phrase: {
                                            "collections.slug": this.slug
                                        }
                                    },
                                ],
                                minimum_should_match: 2
                            }
                        
                })
                .then(response => {
                    this.products = {}
                    setTimeout(() => {
                        this.products = response
                        this.loader = false
                        // console.log(this.products, 'this.products')
                        //this.products.sort((a, b) => (a.createdAt > b.createdAt) ? 1 : ((b.createdAt > a.createdAt) ? -1 : 0));
                        
                    },100)
                })
            }
            if(this.slug && this.sub){
                this.$store.dispatch("ProductRead", {
                            bool: {
                                must:[
                                    {
                                        match_phrase: {
                                            status: 'active'
                                        }
                                    }
                                ],
                                should: [
                                    {
                                        match_phrase: {
                                            event: this.$store.state.event
                                        }
                                    },
                                    {
                                        match_phrase: {
                                            "collections.slug": this.sub
                                        }
                                    },
                                ],
                                minimum_should_match: 2
                            }
                        
                })
                .then(response => {
                    this.products = {}
                    setTimeout(() => {
                        this.products = response
                        this.loader = false
                        // this.products.sort((a, b) => (a.sort_no > b.sort_no) ? 1 : ((b.sort_no > a.sort_no) ? -1 : 0));
                        //this.products.sort((a, b) => (a.createdAt > b.createdAt) ? 1 : ((b.createdAt > a.createdAt) ? -1 : 0));
                        
                        // console.log(this.products)
                    },100)
                })
            }
        },
        filterNav(){
            var x = window.matchMedia("(max-width: 579px)")
            var status = this.active
            x.addListener(myFunction(x))
            function myFunction(x){
            if (x.matches) { 
                    if(status == false){
                        document.getElementById('filter').classList.add('display')
                        document.getElementById("filter").style.transform = 'translate3d(0px, 0px, 0px)'
                        status = true
                    }
                }
            }
        },
        ClosefilterNav(){
            document.getElementById('filter').classList.remove('display')
        },
        trimName(name) {
            // console.log(name)
            if (name)
            return name
            .replace(new RegExp("_", "g"), " ")
            .replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")
            .toUpperCase();
        },
        convertCurrency(amount) {
            var price = false
            var c = false
            if(localStorage.getItem('currencyValue')){
                c = JSON.parse(localStorage.getItem('currencyValue'))
            }
            price =  Math.round(amount/c)
            return price
        },
    }
}
</script>

<style>
.s-collection-product{
    transition: all linear .7s;
}

.image-hover{
    opacity: 1;
    display: none;
}
.s-collection-product:hover .item1{
    display: none;
    opacity: 1;
    transition: all linear .7s;
}
.s-collection-product:hover .image-hover{
    display: block;
    opacity: 1;
}
@keyframes come-op {
  to { 
      transform: translateY(0); 
    }
    0%{ 
        opacity: 0 ;
        display: none;
    }
    100%  { opacity: 1 }
}

.display{
    pointer-events: all !important;
    visibility: visible !important;
    transition: 1s ease-in-out;
}
.s-collection-overview{
    transform: translateY(-100px);
    animation-delay: 10s;
    animation: come-in-fade 2s ease forwards;
}
.section3 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 40vh;
    width: 100%;
}
.s-collection{
    margin: 9.375vw 5vw !important;
    margin-top: 2% !important;
}
.f-st{
    margin-bottom: 3%;
}
@media only screen and (max-width: 580px){
    .s-header-filter:after {
        right: 0;
        bottom: 5vw;
        width: 2.778vw;
        height: 4.167vw;
        content: '';
        position: absolute;
        transform: rotate(90deg);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url('../svg/icon-toggle.svg');
    }
    .s-collection-overview{
        margin: auto;
    }
}
.s-header-filter .f-st,
.s-header-filter .f-hl{
    transform: translateY(-200px);
    animation-delay: 5s;
    animation: come-in-fade 2s ease forwards;
}
@keyframes come-in-fade {
  to { 
      transform: translateY(0); 
    }
    0%{ opacity: 0 }
    100%  { opacity: 1 }
}
@keyframes fade {
  
}

.section3 .f-bs:hover .f-bs-arrow, 
.section3 .f-bs:hover .f-bs-line{
    animation: right-left .5s ease-in-out forwards;
}
.section3 .f-bs:hover .f-bs-label{
    animation: left-right .5s ease-in-out forwards;
}
.f-bs{
    margin-top: 1.375vw;
}
@keyframes right-left{
    0%{
        transform: translate(0px, 0px);
    }
    100%{
        transform: translate(80px, 0px);
    }
}
@keyframes left-right{
    0%{
        transform: translate(0px, 0px);
    }
    100%{
        transform: translate(-70px, 0px);
    }
}
</style>